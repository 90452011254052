@import-normalize;
@import url('https: //fonts.googleapis.com/css2?family=Commissioner:wght@300;600;800&display=swap');

* {
  box-sizing: border-box;
}

:root {
  /* Typography */
  --family: "Commissioner", sans-serif;
  --fs-sm: 14px;
  --fs-md: 16px;
  --fw-light: 300;
  --fw-normal: 600;
  --fw-bold: 800;

  /* Other */
  --border-radius: 0.3rem;
}


body[data-theme='dark'] {
  --colors-text: rgb(210, 208, 208);
  --colors-bg: black;
  --colors-bg-gradient: linear-gradient(45deg, rgba(45, 45, 45, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(48, 48, 48, 1) 100%);
  --colors-ui-base: rgb(27, 27, 27);
  --shadow: rgba(125, 125, 125, 0.6) 0px 0px 5px;
  --shadow2: rgba(125, 125, 125, 0.5) 5px 5px 55px;
}

body[data-theme='light'] {
  --colors-text: black;
  --colors-bg: rgb(251, 251, 251);
  --colors-bg-gradient: linear-gradient(45deg, rgba(251, 251, 251, 1) 0%, rgba(217, 217, 217, 1) 50%, rgba(242, 242, 242, 1) 100%);
  --colors-ui-base: rgb(235, 235, 235);
  --shadow: rgba(125, 125, 125, 0.5) 0px 0px 5px;
  --shadow2: rgba(125, 125, 125, 0.7) 5px 5px 55px;
}

body {
  margin: 0;
  font-family: var(--family);
  color: var(--colors-text);
  font-weight: var(--fw-light);
  background-color: var(--colors-bg);
}

/**
 * Smooth scrolling on the whole document
 */
html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

@media print {
  .scrollToTopButton {
    display: none;
  }
}